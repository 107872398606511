import { Component, OnInit } from '@angular/core';
// import { FormViewerSettings } from '@forms/form-viewer';

@Component({
  selector: 'app-link-generator',
  templateUrl: './link-generator.component.html',
  styleUrls: ['./link-generator.component.css']
})
export class LinkGeneratorComponent implements OnInit {

  settings = {
    formId: this.generateUUID(),
    formTemplateVersionId: '',
    bearerToken: '',
    referenceType: '',
    referenceId: '',
  };

  generatedLink: string = '';
  constructor() { }

  ngOnInit(): void {
  }


  public generateLink() {
    this.generatedLink = `https://form-viewer.sandbox.xactdev.com/form-viewer?token=${this.settings.bearerToken}&formTemplateVersionId=${this.settings.formTemplateVersionId}&refType=${this.settings.referenceType}&refId=${this.settings.referenceId}&formId=${this.settings.formId}`;
  }

  private generateUUID(): string {
    // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    let d2 = 0; // (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = Math.random() * 16; // random number between 0 and 16
        if (d > 0) {
          // Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          // Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }
}
