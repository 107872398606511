import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormViewerWcComponent } from './form-viewer-wc/form-viewer-wc.component';
import { LinkGeneratorComponent } from './link-generator/link-generator.component';


const routes: Routes = [
  { path: 'link-generator', component: LinkGeneratorComponent },
  { path: 'form-viewer', component: FormViewerWcComponent }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
