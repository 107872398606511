
<section class="container">
  <h1>Link Generation</h1>
  <mat-form-field>
		<mat-label>Form Id</mat-label>
		<input matInput [(ngModel)]="settings.formId" placeholder="formId"/>
	</mat-form-field>
  <mat-form-field>
		<mat-label>Form Template Version Id</mat-label>
		<input matInput [(ngModel)]="settings.formTemplateVersionId" placeholder="Form Template Version Id"/>
	</mat-form-field>
  <mat-form-field>
		<mat-label>Bearer Token</mat-label>
		<input matInput [(ngModel)]="settings.bearerToken" placeholder="Bearer Token"/>
	</mat-form-field>
  <mat-form-field>
		<mat-label>Reference Type</mat-label>
		<input matInput [(ngModel)]="settings.referenceType" placeholder="ng-project, xa-project, etc."/>
	</mat-form-field>
  <mat-form-field>
		<mat-label>Reference Id</mat-label>
		<input matInput [(ngModel)]="settings.referenceId" placeholder="estimate-id"/>
	</mat-form-field>

    <button type="button" mat-raised-button="accent" (click)="generateLink()">
      Generate Link
    </button>

  <mat-form-field>
    <mat-label>Generated Link</mat-label>
    <textarea
    readonly
    matInput
    [(ngModel)]="generatedLink"
    rows="10"
    ></textarea>
  </mat-form-field>
</section>
