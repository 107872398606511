import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import '@forms/form-viewer/web-component/unoptimized/main.js'

@Component({
  selector: 'app-form-viewer-wc',
  templateUrl: './form-viewer-wc.component.html',
  styleUrls: ['./form-viewer-wc.component.css'],
})
export class FormViewerWcComponent implements AfterViewInit {

  @ViewChild('viewer')
  public formViewer: ElementRef;

  public settings: any;

  constructor(private route: ActivatedRoute) { }

  formViewerSettings = {
    formId: '',
    formTemplateVersionId: '',
    deploymentEnvironment: 0,
    bearerToken: '',
    referenceType: '',
    referenceId: '',
    cultureCode: 'en-US',
    timezone: 'America/Denver',
    isDemoApp: false,
  }

  public ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.formViewerSettings.bearerToken = params.token;
      this.formViewerSettings.formId = params.formId;
      this.formViewerSettings.formTemplateVersionId = params.formTemplateVersionId;
      this.formViewerSettings.referenceType = params.refType;
      this.formViewerSettings.referenceId = params.refId;
    });

    this.formViewer.nativeElement['formViewerSettings'] = this.formViewerSettings;
  }

}
